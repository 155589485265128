/* #8d0a99 #780783 */

@import "~react-image-gallery/styles/css/image-gallery.css";

body {
  font-family: "Open Sans", sans-serif !important;
  color: #333 !important;
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  background-color: #fff !important;
}
.change-bg {
  color: #fff !important;
  /* background: linear-gradient(60deg, #8d0a99, #f133a2, #8d0a99);
  background-size: 200% 200%;
  animation: gradient-animation 5s ease infinite; */
  background-color: #f133a2 !important;
}
.change-bg:hover {
  background-color: #f860b9 !important;
}
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
h1 {
  color: #f4d4f7;
  font-family: "Dancing Script", cursive;
  font-size: 3.4rem !important;
  font-weight: 800 !important;
  margin-bottom: 2.4rem !important;
  line-height: 4rem !important;
  color: #8d0a99 !important;
}
.h1-small {
  font-size: 2rem;
}
h2 {
  font-family: "Dancing Script", cursive;
  font-weight: 700 !important;
  color: #8d0a99 !important;
  box-shadow: 0px 1px #8d0a99;
  margin-bottom: 16px !important;
}
h3 {
  color: #8d0a99 !important;
  font-weight: 700 !important;
}
h4 {
  font-weight: 600 !important;
  padding-bottom: 1rem;
}
h5 {
  font-weight: 600 !important;
}
h6 {
  color: #333 !important;
}
header {
  /* padding-bottom: 80px; */
  text-align: center;
  color: #333;
}
.scroll-margin {
  scroll-margin-top: 100px;
}
.scroll-arrow {
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 20px;
  height: 2rem;
  width: 2rem;
  color: #3333339d;
}
.max-100 {
  max-width: 100% !important;
}
.max-70 {
  max-width: 70% !important;
}
.max-50 {
  max-width: 100% !important;
}
.dancing-font-s {
  font-family: "Dancing Script", cursive;
  font-size: 1.5rem !important;
}
.navbar {
  background-color: #fff !important;
  padding: 0;
  /* #f7e4f0 */
}
.nav-link {
  font-weight: 600;
  color: #333 !important;
}
.nav-link:hover {
  color: #000 !important;
}
.navi-link {
  color: #333333 !important;
}
.navi-link:hover {
  color: #000 !important;
}
.nav-bron {
  padding: 0.25rem 0.5rem;
  background-color: #f133a2;
  color: #fff;
  font-weight: 600;
  border-radius: 0.3rem;
}
.nav-bron:hover {
  background-color: #f860b9;
}
.navbar-toggler:focus {
  box-shadow: none !important;
  /* border: 2px solid #f133a2; */
}
.navbar-toggler {
  border: none !important;
}
.nav-item.dropdown .dropdown-item:active {
  background-color: #f8f9fa !important;
}
.gray-wrapper {
  background-color: #f1f1f1 !important;
  /* #f7f1e4 #e1f2fa #d5f5de*/
}
.beige-wrapper {
  background-color: #f8f4eb !important;
  /* #f7f1e4 #e1f2fa #d5f5de*/
}
.blue-wrapper {
  background-color: #e7f4fa !important;
  /* #f7f1e4 #e1f2fa #d5f5de*/
}
.green-wrapper {
  background-color: #e6f8ec !important;
  /* #f7f1e4 #e1f2fa #d5f5de*/
}
.pink-wrapper {
  background-color: #fcf2fa !important;
  /* #f7f1e4 #e1f2fa #d5f5de*/
}
.white-wrapper {
  background-color: #ffffffb4;
  padding: 1rem;
  border-radius: 2rem 4rem;
}
.beige-wrapper-intr {
  background-color: #f8f4eb !important;
  border-radius: 2rem 4rem;
  /* #f7f1e4 #e1f2fa #d5f5de*/
}
.blue-wrapper-intr {
  background-color: #e7f4fa !important;
  border-radius: 2rem 4rem;
  /* #f7f1e4 #e1f2fa #d5f5de*/
}
.green-wrapper-intr {
  background-color: #e6f8ec !important;
  border-radius: 2rem 4rem;
  /* #f7f1e4 #e1f2fa #d5f5de*/
}
.pink-wrapper-intr {
  background-color: #fcf2fa !important;
  border-radius: 2rem 4rem;
  /* #f7f1e4 #e1f2fa #d5f5de*/
}
.home {
  background-image: url("./assets/photographerGallery/11.jpg");
  /* background-size: cover; */
  background-repeat: no-repeat;
  padding-top: 3rem;
  border-radius: 1rem;
  margin: auto 0;
}
.home p {
  margin-bottom: 3rem;
}
.home button {
  margin-bottom: 3rem;
}
.hero {
  position: relative;
  border-radius: 1rem;
}
/* .hero img{
  max-width: 200px;
  border: 5px solid #fff;
}
.hero-img1{
  position: absolute;
  left: 0%;
  transform: rotate(5deg) translateX(-3rem);
}
.hero-img2{
  position: absolute;
  left: 25%;
  transform: translateY(-4rem);
  z-index: 1;
}
.hero-img3{
  left: 50%;
  position: absolute;
  transform: rotate(-4deg) translateY(-8rem)
} */
.hero-small-text {
  font-family: "Open Sans", sans-serif !important;
  font-weight: 600 !important;
  font-size: 1rem !important;
  color: #333 !important;
  box-shadow: none;
}
.hero-h2 {
  box-shadow: none !important;
  color: #333 !important;
}
.main_wrapper {
  padding: 30px;
}
.intr-pic {
  max-width: 100%;
  border-radius: 1rem 1rem;
}
.intr-50-pics {
  flex-direction: column;
}
.blog-img {
  border-radius: 1rem;
}
.black-link {
  cursor: pointer;
  color: #333;
  text-decoration: underline #333 !important;
}
.black-link:hover {
  color: #000;
  text-decoration: underline #000 !important;
}
.selectedlist {
  width: 100%;
  max-width: 350px;
  padding: 0 10px;
}
.time-list {
  max-width: 400px;
}
.time-list ul {
  display: inline-block;
  margin: 0;
  padding: 0;
}
.time-list ul li {
  display: block;
  border: 1px solid #777;
  border-radius: 7px 15px;
  margin-bottom: 10px;
}
.hide {
  display: none;
}
.card-img-top.w200 {
  width: 200px;
}
.user-data {
  padding: 15px;
}
.user-data span {
  display: block;
  margin: 5px 0 0 0;
}
.logo {
  max-width: 10rem;
  background-color: #ffffff91;
  border-radius: 1rem;
}
.logo-mob {
  padding: 0;
  margin: 0;
  color: #f133a2 !important;
}
.logo-footer {
  max-width: 8rem;
}
.color-box {
  width: 14px;
  height: 14px;
}
.available {
  background-color: rgb(204, 240, 217) !important;
}
.vacant {
  background-color: rgb(204, 240, 217) !important;
}
.vacant:hover {
  color: white !important;
  background-color: #f133a2 !important;
}
.waiting {
  background-color: rgb(248, 235, 175) !important;
}
.booked {
  background-color: rgb(248, 215, 215) !important;
}
.chosen {
  color: #fff !important;
  background-color: #f133a2 !important;
}
.purple-text {
  color: #f133a2 !important;
}
.purple-bg {
  color: white !important;
  background-color: #f133a2 !important;
}
.purple-bg:hover {
  background-color: #f860b9 !important;
}
.pointer {
  cursor: pointer;
}
.gallery-image {
  overflow: hidden;
  transition: all 0.6s;
}
.w-100 {
  transition: all 0.6s;
}
.w-100:hover {
  transform: scale(1.1);
  transition: all 0.6s;
}
.margin-zero {
  margin-right: 0 !important;
  display: inline-block;
}
/* React Calendar */
.react-calendar {
  width: 100% !important;
  max-width: 400px !important;
  background: white;
  border: 1px solid #a0969c;
  font-family: "Open Sans", sans-serif !important;
  line-height: 1.125em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  color: red;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #969696;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}
button.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
button.react-calendar__tile:enabled:hover,
button.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
button.react-calendar__tile--hasActive:enabled:hover,
button.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #f133a2 !important;
  color: white;
}
button.react-calendar__tile--active:enabled:hover,
button.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}
/* React image gallery */
.image-gallery-icon:hover,
.image-gallery-icon:active {
  color: #f133a2 !important;
}
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:active,
.image-gallery-thumbnail:focus {
  border-color: #f133a2 !important;
}
.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  border-color: #f133a2 !important;
}

@media screen and (max-width: 991px) {
  .dancing-font-s {
    font-size: 1.8rem !important;
  }
  .max-70 {
    max-width: 100% !important;
  }
  .max-50 {
    max-width: 50% !important;
  }
  .intr-50-pics {
    flex-direction: row;
  }
}
@media screen and (max-width: 500px) {
  .hero img {
    max-width: 200px;
  }
}
@media screen and (max-width: 425px) {
  .logo-footer {
    max-width: 200px;
  }
}
.card-body {
  color: #222 !important;
}
.list-group-item {
  color: #333 !important;
}
.form-check-input {
  border-color: #333 !important;
}
.form-check-input:checked {
  border-color: #f133a2 !important;
  background-color: #f860b9 !important;
}
.form-check-input:focus {
  box-shadow: 0 0 0 0.25rem #f133a228 !important;
}
.form-control:focus {
  box-shadow: 0 0 0 0.25rem #f133a228 !important;
  border-color: #f860b9 !important;
}
